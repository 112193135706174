<template>
  <b-modal id="modal-fee-detail" ref="refModalFeeDetail" :title="t('Thông tin dịch vụ')" size="lg" scrollable
    :hide-footer="true" no-close-on-backdrop @show="onOpen" @hidden="resetModal">

    <div class="d-flex justify-content-center mb-1">
      <b-spinner v-if="isLoading" class="text-center" variant="secondary" />
    </div>

    <div v-if="itemLocal && !isLoading">
      <div v-if="itemLocal.quota"
        class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pb-1">
        {{ t('Thông tin dịch vụ') }}
      </div>
      <b-list-group class="">
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Tên dịch vụ') }}:
            </b-col>
            <b-col class=" ">
              {{ itemLocal.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.feeCategory">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Loại dịch vụ') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.feeCategory.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.feeTypeObject">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Loại thu chi') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.feeTypeObject.title }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.feeCalculationTypeObject">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Loại đơn giá') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.feeCalculationTypeObject.title }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="parseInt(itemLocal.price, 10) > 0">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Đơn giá') }}:
            </b-col>
            <b-col class="">
              {{ Number(itemLocal.price).toLocaleString() }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.description && itemLocal.description.length > 0">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Mô tả') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.description }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.feeRanges && itemLocal.feeRanges.length > 0">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Giá trị') }}:
              <vue-good-table :columns="columns" :rows="itemLocal.feeRanges" :pagination-options="{ enabled: false }">
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'range'">
                    {{ props.row.range }}
                  </span>

                  <span v-else-if="props.column.field === 'price'">
                    {{ Number(props.row.price).toLocaleString() }} đ
                  </span>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>


    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BSpinner,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import useFeeDetailModal from './useFeeDetailModal';
import { VueGoodTable } from "vue-good-table";
export default {
  components: {
    BModal,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BSpinner,
    VueGoodTable
  },
  props: {
    feeId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
      t,
    } = useFeeDetailModal(props, emit, refFormObserver);

    const columns = [
      {
        label: 'Định mức',
        field: 'range',
        sortable: false,
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        label: 'Đơn giá',
        field: 'price',
        sortable: false,
        tdClass: 'text-center',
        thClass: 'text-center',
      },
    ]

    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      t,
      columns
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }

    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
</style>
